import LazyImage from '@/components/lazy-image'
import Link from '@/components/link'
import { useCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import { extractHeaderData } from '@/helper/layout/header'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { getOssManyBusinessImageAddress } from '@/constants/oss'
import styles from './index.module.css'

type TLogo = {
  data: ReturnType<typeof extractHeaderData>
  keepWhite?: boolean
}

function Logo(props: TLogo) {
  const { data, keepWhite = false } = props
  const { theme, isMergeMode } = useCommonStore()
  const [url, setUrl] = useState<string>('')
  const renderImage = () => {
    return (
      <>
        {url ? (
          <img src={url} alt={data?.businessName} />
        ) : (
          <span
            style={{
              width: 30,
              height: 30,
            }}
          />
        )}
        {/* <span className={classNames('ml-2 text-lg font-bold', { 'text-white': keepWhite })}>{data?.businessName}</span> */}
      </>
    )
  }
  /* 修改 logo */
  useEffect(() => {
    if (data) {
      if (keepWhite) {
        // setUrl(data.imgWebLogo)
        setUrl(`${getOssManyBusinessImageAddress()}locale_logo_black.png`)
        return
      }
      // setUrl(theme === ThemeEnum.dark ? data.imgWebLogo : data.imgWebWhiteLogo)
      setUrl(`${getOssManyBusinessImageAddress()}locale_logo_${theme === ThemeEnum.dark ? 'black' : 'white'}.png`)
    }
  }, [theme])

  return isMergeMode ? (
    <span className={styles.scoped}>{renderImage()}</span>
  ) : (
    <Link href="/" className={styles.scoped}>
      {renderImage()}
    </Link>
  )
}

export default Logo
